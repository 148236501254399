import { Environment } from '$lib/enum/environment';
import { exists } from '$lib/services/exists';
import { browser } from '$app/environment';

export const getEnvironment = () => {
  if (browser) {
    const environmentOverride = localStorage.getItem(
      'environmentOverride'
    ) as Environment;
    if (Object.values(Environment).includes(environmentOverride)) {
      return environmentOverride;
    }

    const hostname = window?.location?.hostname;
    if (exists(hostname)) {
      if (hostname === 'localhost') {
        return Environment.dev;
      }
      if (hostname === 'www.dev.mra.aofk.net') {
        const pathname = window?.location?.pathname;
        if (exists(pathname) && pathname.indexOf('/review/') === 0) {
          return Environment.review;
        }
        return Environment.dev;
      }
      if (hostname === 'stglogin.ageoflearning.com') {
        return Environment.staging;
      }
      if (hostname === 'login.ageoflearning.com') {
        return Environment.production;
      }
    }
  }
  return Environment.dev;
};
